import React, { useState } from "react";
import { forgotPassword } from "../../../redux/auth/actions";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import EyeIcon from "../../../../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import parsePhoneNumber from "libphonenumber-js";
import { Toaster } from "../../../utils/toaster";
import { APP_CONST } from "../../../config/const";
import { Modal } from "react-bootstrap";

const ForgotPasswords = ({ show, handleClose, handleModalShow }) => {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const handleSubmit = async () => {
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    if (phoneNumber) {
      payload.phone = phoneNumber.nationalNumber;
      payload.countryCode = phoneNumber.countryCallingCode;
      forgotPassword(payload)
        .then((res) => {
          if (res.data.error === false) {
            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "OTP sent");
            // navigate("/reset-password");
            handleClose();
            handleModalShow(APP_CONST.AUTH_MODAL.RESET_PASSWORD);
          }
        })
        .catch((err) => {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
        });
    } else {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "Please enter mobile number");
    }
  };

  return (
    <Modal
      show={show}
      className={"steps-canvas lgn-reg-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
      >
        ×
      </button>
      <Modal.Body>
        <Modal.Title>Forgot Password</Modal.Title>
        <div className="login-section">
          <div className="form-steps">
            <Form>
              <Form.Group className="form-group" controlId="formBasicPassword">
                <PhoneInput
                  country={"in"}
                  countryCodeEditable={false}
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                  onlyCountries={["in", "bd"]}
                  inputStyle={{
                    width: "100%",
                    padding: "0.7rem 0.75rem",
                    paddingLeft: "50px",
                  }}
                  onChange={(phone) => {
                    // alert("phone", phone);
                    // alert(phone);
                    setMobileNumber(phone);
                  }}
                />
              </Form.Group>
            </Form>

            <div className="modal-btn">
              <Button variant="primary" type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ForgotPasswords;
