import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import parsePhoneNumber from "libphonenumber-js";
import {
  mobileVerification,
  sendOtpToMobile,
} from "../../../redux/auth/actions";
import { useDispatch } from "react-redux";
import { Button, Form } from "react-bootstrap";
// import LoginLeftPart from "../../../assets/images/cricket.gif";
import { APP_CONST } from "../../../config/const";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Toaster } from "../../../utils/toaster";
import PhoneInput from "react-phone-input-2";

const MobileNumberModal = ({ show, handleClose, handleModalShow }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const OTP = useSelector((state) => state?.app?.appDetails);
  const { appDetails } = useSelector((state) => state.app);
  const [searchParams, setSearchParams] = useSearchParams();
  const [referalCode, setReferalCode] = useState(searchParams.get("refferal"));
  const [agentCode, setAgentCode] = useState(searchParams.get("agent_code"));
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [otp, setOtpValue] = useState("");
  const [mobileDetails, setMobileDetails] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = () => {
    setIsSubmitting(true);
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    if (phoneNumber) {
      payload.mobile = phoneNumber.nationalNumber;
      payload.country_code = phoneNumber.countryCallingCode;
    }

    dispatch(sendOtpToMobile(payload))
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.error === false) {
          persistDetails(payload);

          if (OTP && OTP?.OTP === 0) {
            // navigate("/verify-number", {
            //   state: { referalCode, agentCode },
            // });
            // handleClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER);
            // handleModalShow(APP_CONST.AUTH_MODAL.VERIFY_NUMBER);
            setShowOtpModal(true);
          } else {
            // navigate("/register", {
            //   state: { referalCode, agentCode },
            // });
            handleClose();
            handleModalShow(APP_CONST.AUTH_MODAL.VERIFY_NUMBER);
          }
        } else {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, res?.data?.message);
        }
      })
      .catch((err) => {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err?.response?.data?.message);
        setIsSubmitting(false);
      });
  };
  const handleSubmitOtpVerify = () => {
    setIsVerifying(true);
    dispatch(mobileVerification({ ...mobileDetails, otp }))
      .then((res) => {
        setIsVerifying(false);
        handleClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER);
        handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
        // navigate("/register", {
        //   state: {
        //     referalCode: location?.state?.referalCode,
        //     agentCode: location?.state?.agentCode,
        //   },
        // });
      })
      .catch((err) => {
        setIsVerifying(false);
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
      });
  };
  const persistDetails = (data) => {
    localStorage.setItem("mobile_details", JSON.stringify(data));
  };
  useEffect(() => {
    getMobileDetails();
  }, [showOtpModal]);
  const getMobileDetails = () => {
    const details =
      localStorage.getItem("mobile_details") &&
      JSON.parse(localStorage.getItem("mobile_details"));
    if (details) {
      setMobileDetails(details);
    }
  };
  return (
    <Modal
      show={show}
      className={"steps-canvas lgn-reg-modal register"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
      >
        ×
      </button>
      <Modal.Body>
        <div className="login-section">
          <Modal.Title>Sign up</Modal.Title>
          <div className="form-steps">
            {!showOtpModal && (
              <Form>
                <Form.Group
                  className="form-group"
                  controlId="formBasicPassword"
                >
                  <PhoneInput
                    country={"in"}
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    onlyCountries={["in", "bd"]}
                    inputStyle={{
                      width: "100%",
                      padding: "0.7rem 0.75rem",
                      paddingLeft: "50px",
                    }}
                    onChange={(phone) => {
                      // alert("phone", phone);
                      // alert(phone);
                      setMobileNumber(phone);
                    }}
                  />
                </Form.Group>
                <div className="modal-btn">
                  <Button
                    variant="primary"
                    className="loginbtn"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting.." : "Send OTP"}
                  </Button>
                </div>
              </Form>
            )}
            {showOtpModal && (
              <>
                <Form>
                  <Form.Group className="form-group" controlId="formBasicPhone">
                    <Form.Control
                      type="tell"
                      placeholder="Enter OTP*"
                      value={`+91 ${mobileNumber}`}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group
                    className="form-group"
                    controlId="formBasicPassword"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter OTP*"
                      onChange={(e) => setOtpValue(e.target.value)}
                    />
                  </Form.Group>
                </Form>

                <div className="modal-btn">
                  <Button
                    variant="primary"
                    onClick={handleSubmitOtpVerify}
                    disabled={isVerifying}
                  >
                    {!isVerifying ? "Verify OTP" : "Submitting..."}
                  </Button>
                </div>
              </>
            )}

            <div className="login">
              <span>Already have an account? </span>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  handleClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER);
                  handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                }}
              >
                Log In
              </a>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default MobileNumberModal;
